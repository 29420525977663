/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import * as React from 'react';
import _ from 'lodash';

// material-ui
import {
    DataGridPro,
    GridColDef,
    useGridApiRef,
    GridRowParams,
    GridAlignment,
    GridRowId,
    GridFilterModel,
    gridFilterModelSelector,
    GridPreferencePanelParams,
    GridPreferencePanelsValue,
    GridSortModel,
    gridSortModelSelector,
    GridLogicOperator,
    GridSortDirection,
    GridState
} from '@mui/x-data-grid-pro';
import { Box, ClickAwayListener, Grid, IconButton, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FileDownload, Share, Visibility } from '@mui/icons-material';
// graphql
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
    QUERY_FIND_RECORD_HEADERS_WITH_FILTERING,
    QUERY_FIND_RECORD_TYPE,
    QUERY_GET_RECORD_FIELDS,
    QUERY_GET_ROLE_ACCESS
} from 'graphql/queries/bills';
import { MUTATION_UPDATE_RECORD_HEADER, MUTATION_BULK_UPDATE_RECORD_HEADER } from 'graphql/mutations/bills';

import { useSelector, useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

import { capitalize } from 'utils/stringHelpers';

import {
    FilterRecordHeaderInput,
    FindRecordHeaderWithFilteringVariables,
    IFindRecordTypeSummarize,
    IGetRecordFields,
    IGetRecordHeadersFiltered,
    IGetRoleAccess,
    IGridPreference,
    IObjectValues,
    IRecordFieldsList,
    IRecordHeaders
} from '../types';
import { FilterPanel, FilterPanelProps, UpdateRowsBanner } from './components';
import SkeletonGrid from './SkeletonGrid';
import {
    getAditionalFields,
    editableFields,
    getEditableField,
    filterNotAllowedFields,
    getRenderEditCell,
    getValueSetter,
    getValueGetter,
    getRenderCell,
    handleRowEditStop,
    getDensityFactor,
    getValueFormatter,
    generateGridState,
    findCellsEdited
} from '../utils';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { IGetRecordFileById, IUpdateBulkRecordHeader, IUpdateRecordHeader, RecordFileByIdVariables } from 'ui-component/RecordView/types';
import useMyRole from 'hooks/useMyRole';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import CustomDetailPanel from '../CustomDetailPanel';
import CloseDetailsPanelDialog from '../CustomDetailPanel/CloseDetailsPanelDialog';
import { IFindAllLineItemHeaders } from '../CustomDetailPanel/types';
import { QUERY_FIND_ALL_LINE_ITEMS_HEADERS } from 'graphql/queries/lineItems';
import { QUERY_GET_MY_RECORDS_FILE_BY_ID } from 'graphql/queries/records';
import { downloadPdf, getDataToUpdate, getDocumentFileUrl } from 'ui-component/RecordView/utils';
import {
    NON_EDITABLE_DATA_TYPES,
    NON_FILTERABLE_OR_SORTABLE_FIELDS,
    NON_SORTABLE_DATA_TYPES,
    checkFilterValue,
    generateFilterPayloads,
    getFilterOperator
} from '../utils/filterHelpers';
import { getAditionalFieldsHeaders, getBaseFields } from 'ui-component/RecordView/PropertiesPanel/utils';
import { CustomToolbar, ICustomToolbar } from 'ui-component/gridToolbar/CustomToolbar';
import { checkIsEditing, generateEditModel, generateUpdatedList, generateViewModel, handleCellKeydown } from '../utils/editHelpers';
import { PaginationFooter, PaginationFooterProps } from './components/PaginationFooter';
import { getRowCustomClassName } from '../utils/rowHelpers';
import { useConfirmationModalContext } from 'hooks/useConfirmationModal';
import { GoogleAnalyticsEventHandler } from 'index';
import BulkEditDialog from './components/BulkEditDialog';
import { GridOptions } from '../GridOptions';
import { getListIdsFromHeaders, getTogglableColumns } from '../utils/headerHelpers';
import { useCustomListValues } from 'hooks/useCustomListValues';
import ObjectPanelEditForm from './components/ObjectPanelEditForm';
import { FindObjectProperty, FindObjectPropertyVariables, ObjectProperty } from 'views/backoffice/CustomObjects/types';
import { QUERY_GET_OBJECT_PROPERTIES } from 'graphql/queries/customObjects';
import ObjectPanelView from './components/ObjectPanelView';
import { useQueryParams } from 'hooks/useQueryParams';

const useStyles = makeStyles((theme) => ({
    gridOptionsButton: {
        padding: '4px',
        paddingLeft: '0',
        '&:hover': { borderRadius: '0 !important' }
    },
    evenRow: {
        '& div button, div a': { color: '#616161' },
        '&:hover svg': {
            fill: 'white'
        },
        '&:hover, &.MuiDataGrid-row.Mui-hovered': {
            backgroundColor: `${theme.palette.primary.dark} !important`,
            color: '#FFF !important',
            '& div button, div a, .gridObjectValue': { color: '#FFF' },
            '& div button.MuiIconButton-root': { color: '#616161' },
            '& .MuiAutocomplete-popupIndicator, .MuiAutocomplete-popupIndicator > svg, .MuiAutocomplete-clearIndicator, .MuiAutocomplete-clearIndicator > svg':
                {
                    backgroundColor: 'transparent !important',
                    fill: `${theme.palette.primary.dark} !important`
                },
            '& svg[data-testid="CheckBoxIcon"], svg[data-testid="CheckBoxOutlineBlankIcon"], svg[data-testid="VisibilityIcon"], svg[data-testid="FileDownloadIcon"], svg[data-testid="ShareIcon"], svg[data-testid="AddIcon"]':
                {
                    color: '#FFF !important',
                    backgroundColor: 'transparent !important'
                },
            '& .MuiDataGrid-cell--editing, .MuiDataGrid-cell--editing>div>div>div>div>div, .MuiDataGrid-cell--editing>div>div>div>div>div>div>div, .MuiDataGrid-cell--editing > .MuiBox-root > .MuiFormControl-root > .MuiInputBase-root, .MuiDataGrid-cell--editing input, div[aria-haspopup="listbox"]':
                {
                    backgroundColor: `${theme.palette.secondary.light} !important`,
                    '& svg[data-testid="CheckBoxIcon"], svg[data-testid="CheckBoxOutlineBlankIcon"]': {
                        color: `${theme.palette.primary.dark} !important`
                    }
                }
        }
    },
    oddRow: {
        backgroundColor: `${theme.palette.primary[200]} !important`,
        '& div button, div a': { color: '#616161' },
        '&:hover svg': {
            fill: 'white'
        },
        '&:hover, &.MuiDataGrid-row.Mui-hovered': {
            backgroundColor: `${theme.palette.primary.dark} !important`,
            color: '#FFF !important',
            '& div button, div a, .gridObjectValue': { color: '#FFF' },
            '& div button.MuiIconButton-root': { color: '#616161' },
            '& .MuiAutocomplete-popupIndicator, .MuiAutocomplete-popupIndicator > svg, .MuiAutocomplete-clearIndicator, .MuiAutocomplete-clearIndicator > svg':
                {
                    backgroundColor: 'transparent !important',
                    fill: `${theme.palette.primary.dark} !important`
                },
            '& svg[data-testid="CheckBoxIcon"], svg[data-testid="CheckBoxOutlineBlankIcon"], svg[data-testid="VisibilityIcon"], svg[data-testid="FileDownloadIcon"], svg[data-testid="ShareIcon"], svg[data-testid="AddIcon"]':
                {
                    color: '#FFF !important',
                    backgroundColor: 'transparent !important'
                },
            '& .MuiDataGrid-cell--editing, .MuiDataGrid-cell--editing>div>div>div>div>div, .MuiDataGrid-cell--editing>div>div>div>div>div>div>div, .MuiDataGrid-cell--editing > .MuiBox-root > .MuiFormControl-root > .MuiInputBase-root, .MuiDataGrid-cell--editing input, div[aria-haspopup="listbox"]':
                {
                    backgroundColor: `${theme.palette.secondary.light} !important`,
                    '& svg[data-testid="CheckBoxIcon"], svg[data-testid="CheckBoxOutlineBlankIcon"]': {
                        color: `${theme.palette.primary.dark} !important`
                    }
                }
        }
    },
    gridToolbarCustomFont: { fontSize: '0.8125rem', fontWeight: '500', color: '#858585', marginLeft: '4px' },
    gridToolbarCustomFontBlack: { fontSize: '0.8125rem', fontWeight: '500', color: '#000', marginLeft: '4px' },
    paginationItems: { display: 'flex', justifyContent: 'flex-end' },
    rowsPerPageSelectBox: {
        display: 'flex',
        alignItems: 'center',
        '&:hover': { cursor: 'pointer' }
    },
    paginationArrow: { fontSize: '1rem', fontWeight: '500', color: '#203461', marginLeft: '4px' },
    paginationControl: {
        '&:hover': {
            backgroundColor: 'transparent !important',
            borderRadius: '0 !important'
        },
        '&:disabled': {
            '& > svg': {
                color: '#C3C3C3 !important'
            }
        }
    },
    disabledRow: {
        opacity: 0.7
    }
}));

export interface IRecordGrid {
    recordTypeId: number;
    gridName: string;
}

export const RecordGrid = ({ recordTypeId, gridName = 'bills' }: IRecordGrid) => {
    const classes = useStyles();
    const storeDispatch = useDispatch();
    const apiRef = useGridApiRef();
    const intl = useIntl();
    const queryParams = useQueryParams();
    const { pathname: currentPathName } = useLocation();
    const navigate = useNavigate();
    const roleId = useMyRole();
    const modal = useConfirmationModalContext();
    const { actionData, selectedItem } = useSelector((store) => store.menu);

    const isAllowedDelete = useMemo(
        () =>
            actionData
                .find((el) => Number(el.menuItem.id) === Number(selectedItem) && Number(el.role.id) === (roleId as number))
                ?.menuItemActions.find((el) => el.name.toLowerCase().includes('soft delete'))?.enabled,
        [actionData, roleId, selectedItem]
    );

    const isAllowedLineItemAccess = useMemo(
        () =>
            actionData
                .find((el) => Number(el.menuItem.id) === Number(selectedItem) && Number(el.role.id) === (roleId as number))
                ?.menuItemActions.find((el) => el.name.toLowerCase().includes('line item access'))?.enabled,
        [actionData, roleId, selectedItem]
    );

    // const isDisableEditAction = !actionData
    //     .find((el) => Number(el.menuItem.id) === Number(menuItem.id))
    //     ?.menuItemActions.find((action: IMenuItemActionRoleActions) => Number(action.id) === 3);

    const hasLoadedInitialStateRef = useRef(false);

    const [recordList, setRecordList] = useState<IRecordHeaders[]>([]);
    const [inputSearch, setInputSearch] = useState<string>('');
    // Super Record Type
    const [isSuperRecordType, setIsSuperRecordType] = useState(false);
    const [headerList, setHeaderList] = useState<IRecordFieldsList | undefined>(undefined);
    const [showObjectPanel, setShowObjectPanel] = useState(false);
    // Objects
    const [objectPanelMode, setObjectPanelMode] = useState('view');
    const [objectProperties, setObjectProperties] = useState<(ObjectProperty & { value: string })[]>([]);
    const [selectedObjectToEdit, setSelectedObjectToEdit] = useState<{ id: number; field: string } | null>(null);
    const [isAllowedToEditObject, setIsAllowedToEditObject] = useState(true);
    const [isListObjectSelected, setIsListObjectSelected] = useState(true);
    const objectPanelRef = useRef<HTMLDivElement | null>(null);

    // Grid Preferences states
    const [showResetRowFilters, setShowResetRowFilters] = useState(false);
    const [showResetRowOrder, setShowResetRowOrder] = useState(false);
    const [defaultGrid, setDefaultGrid] = useState<IGridPreference | null | undefined>(undefined);
    const [loadingPreferences, setLoadingPreferences] = useState(false);
    const densityFactorRef = useRef<number>(1);
    const [showSaveChanges, setShowSaveChanges] = useState(false);
    const [currentState, setCurrentState] = useState<GridInitialStatePro>();
    const [initialState, setInitialState] = React.useState<{
        state?: GridInitialStatePro;
        loading: boolean;
    }>({ loading: true });

    // Pagination and filtering states
    const [isLoadingPage, setIsLoadingPage] = useState(false);
    const [showOnlyEnabledRecords, setShowOnlyEnabledRecords] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageSize, setCurrentPageSize] = useState(25);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [filters, setFilters] = useState<FilterRecordHeaderInput | undefined>();
    const [newFilterModel, setNewFilterModel] = useState<GridFilterModel>();
    const [appliedFilterModel, setAppliedFilterModel] = useState<GridFilterModel>();

    // Edit states
    const [cellModesModel, setCellModesModel] = useState<Record<string, any>>({});
    const [checkbox, setCheckbox] = useState<string[]>([]);
    // Bulk Edit States
    const [bulkEditDialogOpen, setBulkEditDialogOpen] = useState(false);
    const [columnsEnabledToBulkEdit, setColumnsEnabledToBulkEdit] = useState<string[]>([]);

    // Line items states
    const [showCloseDetailsPanel, setShowCloseDetailsPanel] = useState(false);
    const [isEditingLineItem, setIsEditingLineItem] = useState(false);
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);
    const [newRowId, setNewRowId] = useState<number | null>(null);

    const isSameFilterModel = useMemo(
        () => _.isEqual(_.omit(newFilterModel, ['id']), _.omit(appliedFilterModel, ['id'])),
        [newFilterModel, appliedFilterModel]
    );

    const [getRecordFields, { loading: headerLoading, error: headerError }] = useLazyQuery<IGetRecordFields>(QUERY_GET_RECORD_FIELDS);

    useQuery<IFindRecordTypeSummarize>(QUERY_FIND_RECORD_TYPE, {
        variables: { data: { id: recordTypeId || 3 } },
        fetchPolicy: 'no-cache',
        onCompleted: async (data) => {
            if (data?.findRecordType) {
                setIsSuperRecordType(data.findRecordType[0].isSuper);
                const recordTypesIds = [recordTypeId];
                if (data.findRecordType[0].isSuper) {
                    data.findRecordType[0].relatedRecordTypes.forEach((recordType) => {
                        recordTypesIds.push(+recordType.id);
                    });
                }
                let headerListRaw: IRecordFieldsList | undefined;
                for await (const id of recordTypesIds) {
                    const { data } = await getRecordFields({ variables: { recordType: id } });
                    if (data?.getSaasRecordFieldsByRecordTypes) {
                        const newHeaders = { ...data.getSaasRecordFieldsByRecordTypes };
                        Object.keys(newHeaders).forEach((key) => {
                            newHeaders[key] = { ...newHeaders[key], recordTypeId: id };
                        });
                        headerListRaw = { ...headerListRaw, ...newHeaders };
                    }
                }
                setHeaderList(headerListRaw);
            }
        }
    });

    const {
        data: fieldAccessData,
        loading: accessLoading,
        error: accessError
    } = useQuery<IGetRoleAccess>(QUERY_GET_ROLE_ACCESS, {
        fetchPolicy: 'no-cache'
    });

    const [getRecords, { data: listData, loading: listLoading, fetchMore }] = useLazyQuery<
        IGetRecordHeadersFiltered,
        FindRecordHeaderWithFilteringVariables
    >(QUERY_FIND_RECORD_HEADERS_WITH_FILTERING, {
        fetchPolicy: 'no-cache',
        onCompleted(data) {
            setRecordList(data.filterAndSortRecordHeader.records);
            if (!isSameFilterModel) setAppliedFilterModel(newFilterModel);
        },
        onError(error) {
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Failed to fetch grid preferences: ${error.message.replaceAll(':', ',')}`,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
        }
    });

    const [getObjectProperties, { loading: loadingObjectProperties }] = useLazyQuery<FindObjectProperty, FindObjectPropertyVariables>(
        QUERY_GET_OBJECT_PROPERTIES
    );

    const [getRecordFile] = useLazyQuery<IGetRecordFileById, RecordFileByIdVariables>(QUERY_GET_MY_RECORDS_FILE_BY_ID);
    const [updateRecordHeader, { loading: updateLoading }] = useMutation<IUpdateRecordHeader>(MUTATION_UPDATE_RECORD_HEADER, {
        update(cache, { data }) {
            const updatedRecord = data?.updateRecordHeader;

            const list = cache.readQuery<IGetRecordHeadersFiltered, FindRecordHeaderWithFilteringVariables>({
                query: QUERY_FIND_RECORD_HEADERS_WITH_FILTERING,
                variables: {
                    pagination: {
                        offset: currentPage * currentPageSize,
                        limit: currentPageSize
                    },
                    data: {
                        recordTypeId: recordTypeId || 3,
                        tenantId: Number(localStorage.getItem('tenant_id')),
                        enabled: showOnlyEnabledRecords,
                        filters
                    }
                }
            });

            if (!updatedRecord || !list?.filterAndSortRecordHeader) return;

            const newList = generateUpdatedList(list.filterAndSortRecordHeader.records, updatedRecord, showOnlyEnabledRecords);
            const newTotal = showOnlyEnabledRecords && !updatedRecord.enabled ? rowCountState - 1 : rowCountState;
            cache.writeQuery<IGetRecordHeadersFiltered, FindRecordHeaderWithFilteringVariables>({
                query: QUERY_FIND_RECORD_HEADERS_WITH_FILTERING,
                variables: {
                    pagination: {
                        offset: currentPage * currentPageSize,
                        limit: currentPageSize
                    },
                    data: {
                        recordTypeId: recordTypeId || 3,
                        tenantId: Number(localStorage.getItem('tenant_id')),
                        enabled: showOnlyEnabledRecords || undefined,
                        filters
                    }
                },
                data: {
                    filterAndSortRecordHeader: {
                        records: newList,
                        total: newTotal
                    }
                }
            });
        }
    });

    const [updateBulkRecordHeader] = useMutation<IUpdateBulkRecordHeader>(MUTATION_BULK_UPDATE_RECORD_HEADER, {});

    const fieldAccessList = useMemo(() => fieldAccessData?.getRoleAccessToRecordFields, [fieldAccessData]);
    const headerListIds = useMemo(() => getListIdsFromHeaders(headerList), [headerList]);
    const { listValuesByListId, loading: loadingListsValues } = useCustomListValues(headerListIds);

    const handleClickObject = useCallback(
        async (
            field: string,
            rowId: number,
            objectValuesDefinition: IObjectValues[],
            isEditable: boolean,
            objectDefinitionId,
            isListObject = false
        ) => {
            setSelectedObjectToEdit({ id: +rowId, field });
            if (!objectDefinitionId && !headerList?.[field]) return;
            setShowObjectPanel(true);
            const { data: objectPropertiesData } = await getObjectProperties({
                variables: {
                    data: {
                        objectDefinitionIds: +(objectDefinitionId ?? headerList?.[field].objectDefinition.id)
                    }
                }
            });
            const mappedProperties = [...(objectPropertiesData?.findObjectProperty ?? [])]
                .filter((property) => property.enabled)
                .sort((a, b) => {
                    const orderA = a.order;
                    const orderB = b.order;
                    if (orderA < orderB) {
                        return -1;
                    }
                    if (orderA > orderB) {
                        return 1;
                    }
                    return 0;
                })
                .map((property) => ({
                    ...property,
                    value: objectValuesDefinition.find((item) => Number(item.objectProperty.id) === Number(property.id))?.value || ''
                }));
            setObjectProperties(mappedProperties || []);
            setIsAllowedToEditObject(isEditable);
            setIsListObjectSelected(isListObject);
        },
        [headerList, getObjectProperties]
    );

    const handleEditObjectFormInteraction = async (newProperties: (ObjectProperty & { value: string })[], modalTitle: string) => {
        await modal.showConfirmation({
            title: modalTitle,
            content: ``,
            loadingText: 'Updating',
            forwardButtonText: 'Save changes',
            actionButtonText: 'Discard changes',
            onForward: async () => handleUpdateObject(newProperties),
            onAction: () => {
                setSelectedObjectToEdit(null);
                setShowObjectPanel(false);
                setObjectProperties([]);
                setObjectPanelMode('view');
            },
            showCheckbox: true,
            actionType: `${modalTitle === 'Do you want to save this changes?' ? `save` : `discard`}_object_changes`,
            sectionTitle: 'record_grid',
            recordType: String(gridName.toLowerCase().replaceAll(' ', '_')),
            hideCloseBtn: true
        });
    };

    const handleUpdateObject = async (newProperties: (ObjectProperty & { value: string })[]) => {
        const record: { [key: string]: number | string } = {};
        record.id = selectedObjectToEdit?.id || '';
        record[`${selectedObjectToEdit?.field}`] = '';
        const baseFieldHeaders = getBaseFields(headerList || null, roleId, String(recordTypeId), fieldAccessList);
        const aditionalFieldHeaders = getAditionalFieldsHeaders(headerList, roleId, String(recordTypeId), fieldAccessList) || null;
        const selectedRecord = listData?.filterAndSortRecordHeader.records.find(
            (el) => Number(el.id) === Number(record.id)
        ) as IRecordHeaders;
        const { additionalFields, baseData } = getDataToUpdate(
            record,
            baseFieldHeaders,
            aditionalFieldHeaders,
            selectedRecord?.additionalFields || []
        );

        const data: any = {
            ...baseData,
            id: Number(baseData.id)
        };

        data.additionalFields = additionalFields;
        data.additionalFields[0].objectValuesByProperty = newProperties
            // .filter((property) => property.dataType !== 'checkbox')
            .map((property) => ({
                propertyId: +property.id,
                value: property.value
            }));
        try {
            const res = await new Promise<{ success: boolean; message?: string }>((res) => {
                updateRecordHeader({ variables: { data } })
                    .then(({ data }) => {
                        setRecordList((prev) => prev.map((el) => (el.id === data?.updateRecordHeader.id ? data?.updateRecordHeader : el)));
                        res({ success: true });
                    })
                    .catch((error) => res({ success: false, message: error.message }));
            });
            if (res.success) {
                storeDispatch(
                    openSnackbar({
                        open: true,
                        message: `Changes saved!`,
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
            } else {
                storeDispatch(
                    openSnackbar({
                        open: true,
                        message: `Save changes failed: ${res.message ?? 'Unexpected error.'}`,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            }
        } catch (error: any) {
            console.log('!!!!! error');
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Save changes failed: ${error.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
        setSelectedObjectToEdit(null);
        setShowObjectPanel(false);
        setObjectProperties([]);
        setObjectPanelMode('view');
    };

    // Line Items
    const {
        data: lineItemsData,
        loading: loadingLineItems,
        error: lineItemsError
    } = useQuery<IFindAllLineItemHeaders>(QUERY_FIND_ALL_LINE_ITEMS_HEADERS);

    const lineItemObj = useMemo(
        () =>
            lineItemsData?.findAllLineItemsByType.find((data) => {
                const recordTypesArr = data.recordType.map((el) => Number(el.id));
                return recordTypesArr.includes(recordTypeId);
            }) || null,
        [lineItemsData, recordTypeId]
    );

    const handleViewRecord = useCallback((recordId: string, recordType: string, type: 'document' | 'recordDetails' = 'document') => {
        const tenantUrl = localStorage.getItem('tenantPath');
        if (!tenantUrl) return;
        if (type === 'document') window.open(`${tenantUrl}/accounting/doc-viewer/?recordId=${recordId}&recordType=${recordType}`, '_blank');
        else window.open(`${tenantUrl}/record-details-viewer/?recordId=${recordId}&recordType=${recordType}`, '_blank');
    }, []);

    const handleRecordShareURL = useCallback(
        (recordId: string, type: string) => {
            const tenantUrl = localStorage.getItem('tenantPath');
            if (!tenantUrl) return;
            navigator.clipboard.writeText(
                window.location.host.includes('localhost')
                    ? `${window.location.host}${tenantUrl}/accounting/doc-viewer/?recordId=${recordId}&recordType=${type}`
                    : `https://${window.location.host}${tenantUrl}/accounting/doc-viewer/?recordId=${recordId}&recordType=${type}`
            );
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: 'Record link copied to clipboard',
                    variant: 'alert',
                    alert: { color: 'success', severity: 'success' },
                    close: false
                })
            );
        },
        [storeDispatch]
    );

    const handleDownloadFile = useCallback(
        async (fileId: string) => {
            try {
                const { data } = await getRecordFile({
                    variables: {
                        recordFileId: Number(fileId)
                    }
                });
                const recordFileUrl = data?.getMyRecordFileById ? getDocumentFileUrl(data.getMyRecordFileById) : null;
                if (recordFileUrl) downloadPdf(recordFileUrl, data?.getMyRecordFileById.originalFileName);
                else throw new Error('File not found');
            } catch (err: any) {
                console.log(err);
                storeDispatch(
                    openSnackbar({
                        open: true,
                        message: `Download failed: ${err.message || 'Internal server error.'}`,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
            }
        },
        [getRecordFile, storeDispatch]
    );

    const handleNewOrder = () => {
        GoogleAnalyticsEventHandler({ action: 'open_create_form', category: 'Grid', label: gridName });
        navigate(`../create-record?recordTypeId=${recordTypeId}`, { state: { prev: currentPathName } });
    };

    const formatHeaderName = useCallback(
        (key: string) => (key.includes(' ') ? key : intl.formatMessage({ id: key, defaultMessage: key })),
        [intl]
    );

    // Grid Data
    const headerFields: GridColDef[] = useMemo(() => {
        if (!headerList) return [];
        const widerFields = ['datetime', 'multiselect', 'multiselect-checkbox', 'dropdown'];

        return [
            {
                field: 'actions',
                type: 'actions',
                disableReorder: true,
                disableExport: true,
                align: 'left',
                renderCell: (params) => (
                    <Stack
                        direction="row"
                        alignItems="start"
                        className={params.row.recordFile?.id ? 'download-available-row' : 'download-unavailable-row'}
                        data-recordtypeid={recordTypeId}
                        data-recordid={params.id}
                    >
                        <IconButton size="small" onClick={() => handleRecordShareURL(String(params.id), params.row.recordType.id)}>
                            <Share fontSize="small" />
                        </IconButton>

                        <IconButton size="small" onClick={() => handleViewRecord(String(params.id), params.row.recordType.id)}>
                            <Visibility fontSize="small" />
                        </IconButton>

                        {params.row.recordFile?.id && (
                            <IconButton
                                size="small"
                                className="download-button"
                                onClick={() => handleDownloadFile(String(params.row.recordFile?.id || ''))}
                            >
                                <FileDownload fontSize="small" />
                            </IconButton>
                        )}
                    </Stack>
                ),
                renderHeader: () => 'Actions'
            },
            ...Object.keys(headerList)
                .filter((key) => ['standart', 'standard'].includes(headerList[key as keyof IRecordFieldsList].visibility.toLowerCase()))
                .filter(filterNotAllowedFields(fieldAccessList, recordTypeId, roleId))
                .filter((key) => (key === 'enabled' ? isAllowedDelete : true))
                .filter((key) => key !== 'id')
                .map(
                    (key) =>
                        ({
                            field: key,
                            numeric: ['decimal', 'float', 'number'].includes(headerList[key as keyof IRecordFieldsList].dataType),
                            headerName: formatHeaderName(key),
                            headerClassName: `${capitalize(key)} ${
                                getEditableField(key, fieldAccessList, recordTypeId, roleId) ?? editableFields[key]
                                    ? 'bills-header'
                                    : 'bills-header-default'
                            }`,
                            align: 'left' as GridAlignment,
                            width: widerFields.includes(headerList[key as keyof IRecordFieldsList].dataType) ? 250 : 150,
                            filterable: !NON_FILTERABLE_OR_SORTABLE_FIELDS.includes(key),
                            sortable:
                                !NON_FILTERABLE_OR_SORTABLE_FIELDS.includes(key) &&
                                !NON_SORTABLE_DATA_TYPES.includes(headerList[key as keyof IRecordFieldsList].dataType),
                            editable:
                                !!getEditableField(key, fieldAccessList, recordTypeId, roleId) &&
                                !NON_EDITABLE_DATA_TYPES.includes(headerList[key as keyof IRecordFieldsList].dataType),
                            valueGetter: getValueGetter(key),
                            valueSetter: getValueSetter(key),
                            renderCell: getRenderCell(
                                key,
                                !!getEditableField(key, fieldAccessList, recordTypeId, roleId),
                                handleClickObject,
                                headerList[key as keyof IRecordFieldsList],
                                headerList[key as keyof IRecordFieldsList]?.listType?.id
                                    ? listValuesByListId[Number(headerList[key].listType?.id)]
                                    : undefined
                            ),
                            filterOperators: getFilterOperator(key, recordTypeId, headerList[key as keyof IRecordFieldsList]),
                            renderEditCell: getRenderEditCell(
                                key,
                                recordTypeId,
                                headerList[key as keyof IRecordFieldsList],
                                headerList[key as keyof IRecordFieldsList]?.listType?.id
                                    ? listValuesByListId[Number(headerList[key].listType?.id)]
                                    : undefined
                            ),
                            valueFormatter: getValueFormatter(
                                key,
                                headerList[key as keyof IRecordFieldsList].dataType,
                                headerList[key as keyof IRecordFieldsList],
                                headerList[key as keyof IRecordFieldsList]?.listType?.id
                                    ? listValuesByListId[Number(headerList[key].listType?.id)]
                                    : undefined
                            )
                        } as GridColDef)
                )
        ];
    }, [
        headerList,
        fieldAccessList,
        recordTypeId,
        roleId,
        handleRecordShareURL,
        handleViewRecord,
        handleDownloadFile,
        isAllowedDelete,
        formatHeaderName,
        handleClickObject,
        listValuesByListId
    ]);

    const tableList = useMemo(
        () =>
            !headerList
                ? []
                : recordList
                      .map((row: any) =>
                          row.additionalFields.length === 0 ? row : { ...row, ...getAditionalFields(row.additionalFields, headerList) }
                      )
                      .filter((x: Record<string, string>) =>
                          Object.values(x).some((value: string) => value?.toLowerCase?.().includes(inputSearch.trim().toLowerCase()))
                      ),
        [recordList, headerList, inputSearch]
    );

    // Edit handlers
    const ToggleEdit = () => {
        const isEditing = checkIsEditing(cellModesModel);

        if (isEditing) {
            const newViewModel = generateViewModel(cellModesModel);
            setCellModesModel(newViewModel);
        } else {
            const newViewModel = generateEditModel(headerFields, checkbox);
            setCellModesModel(newViewModel);
        }
    };

    const handleEditRowsModelChange = (model: Record<string, any>) => {
        setCellModesModel(model);
    };

    const [bulkUpdateData, setBulkUpdateData] = useState<{ value: number; total: number; results: boolean[] }>({
        value: 0,
        total: 0,
        results: []
    });
    const bulkUpdatePercentage = useMemo(() => (bulkUpdateData.value * 100) / bulkUpdateData.total ?? -1, [bulkUpdateData]);

    const onSaveEdition = async () => {
        modal.hideConfirmation();
        const clearedObjList = findCellsEdited(apiRef.current, cellModesModel);
        // Headers
        const baseFieldHeaders = getBaseFields(headerList || null, roleId, String(recordTypeId), fieldAccessList);
        const aditionalFieldHeaders = getAditionalFieldsHeaders(headerList, roleId, String(recordTypeId), fieldAccessList) || null;

        const updateRequestList = clearedObjList.map((record) => {
            const selectedRecord = recordList.find((el) => Number(el.id) === Number(record.id)) as IRecordHeaders;

            const { additionalFields, baseData } = getDataToUpdate(
                record,
                baseFieldHeaders,
                aditionalFieldHeaders,
                selectedRecord?.additionalFields || []
            );

            const data: any = {
                ...baseData,
                id: Number(baseData.id)
            };

            if (additionalFields?.length) data.additionalFields = additionalFields;

            return data;
        });

        const localBulkData = { ...bulkUpdateData, total: updateRequestList.length };
        setBulkUpdateData({ ...bulkUpdateData, total: updateRequestList.length });

        try {
            for await (const request of updateRequestList) {
                const res = await new Promise<boolean>((res) => {
                    updateRecordHeader({ variables: { data: request } })
                        .then(({ data }) => {
                            setRecordList((prev) =>
                                prev.map((el) => (el.id === data?.updateRecordHeader.id ? data?.updateRecordHeader : el))
                            );
                            res(true);
                        })
                        .catch(() => res(false));
                });
                localBulkData.results = [...localBulkData.results, res];
                if (res) {
                    localBulkData.value += 1;
                    setBulkUpdateData((prev) => ({ ...prev, value: prev.value + 1, results: [...prev.results, res] }));
                } else {
                    setBulkUpdateData((prev) => ({ ...prev, results: [...prev.results, res] }));
                }
            }
            setBulkUpdateData({ total: 0, value: 0, results: [] });
            const successLength = localBulkData.results.filter((el) => el).length;
            ToggleEdit();

            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `${successLength} of ${localBulkData.total} Changes saved!`,
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        } catch (err: any) {
            console.log(err);
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Save changes failed: ${err.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };

    const handleSaveBulkEditionSaveConfirmation = async (attribute: string, Value: any) => {
        setBulkEditDialogOpen(false);
        await modal.showConfirmation({
            title: 'Keep Changes?',
            content: `Are you sure you want to keep these changes?`,
            loadingText: 'Updating',
            forwardButtonText: 'Yes, Confirm',
            actionButtonText: 'No, Cancel',
            onForward: async () => handleSaveBulkEdition(attribute, Value),
            onAction: null,
            showCheckbox: true,
            actionType: 'bulk_edit',
            sectionTitle: 'record_grid',
            recordType: String(gridName.toLowerCase().replaceAll(' ', '_'))
        });
    };

    const handleSaveBulkEdition = async (attribute: string, Value: any) => {
        setIsLoadingPage(true);
        const idsToUpdate = Object.values(Object.fromEntries(apiRef.current.getSelectedRows().entries())).map((el) => el.id);
        const clearedObjList = idsToUpdate.map((id) => {
            const object: { [key: string]: any } = { id };
            object[attribute] = Value;
            return object;
        });

        // Headers
        const baseFieldHeaders = getBaseFields(headerList || null, roleId, String(recordTypeId), fieldAccessList);
        const aditionalFieldHeaders = getAditionalFieldsHeaders(headerList, roleId, String(recordTypeId), fieldAccessList) || null;

        const selectedRecord = recordList.find((el) => Number(el.id) === Number(idsToUpdate[0])) as IRecordHeaders;

        const { additionalFields, baseData } = getDataToUpdate(
            clearedObjList[0],
            baseFieldHeaders,
            aditionalFieldHeaders,
            selectedRecord?.additionalFields || []
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, ...baseDataWithoutId } = baseData;

        const fieldToUpdate: any = {
            ...baseDataWithoutId
        };

        if (additionalFields?.length) fieldToUpdate.additionalFields = additionalFields;

        const updateRequest = { ...fieldToUpdate, tenant: Number(localStorage.getItem('tenant_id')) };

        try {
            await new Promise<boolean>((res) => {
                updateBulkRecordHeader({ variables: { ids: idsToUpdate.map((id) => Number(id)), data: updateRequest } })
                    .then(({ data }) => {
                        const { updateBulkRecordHeader } = data || { updateBulkRecordHeader: [] };
                        updateBulkRecordHeader.forEach((updated) => {
                            setRecordList((prev) => prev.map((el) => (el.id === updated.id ? updated : el)));
                        });
                        res(true);
                    })
                    .catch(() => res(false));
            });

            apiRef.current.setRowSelectionModel([]);
            handleGridRefresh();
            setIsLoadingPage(false);
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Changes saved!`,
                    subtitle: `${idsToUpdate.length} Records updated`,
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        } catch (err: any) {
            console.log(err);
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Save changes failed: ${err.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
    };

    const handleDelete = async () => {
        const successdeletedlist: number[] = [];
        const updateRequestList = checkbox.map((recordId) => ({ id: Number(recordId), enabled: false }));

        try {
            for (const data of updateRequestList) {
                // eslint-disable-next-line no-await-in-loop
                const res = await new Promise<boolean>((res) => {
                    updateRecordHeader({ variables: { data } })
                        .then(() => res(true))
                        .catch(() => res(false));
                });
                if (res) successdeletedlist.push(data.id);
            }

            if (successdeletedlist.length === updateRequestList.length) {
                apiRef.current.setRowSelectionModel([]);
                setCheckbox([]);
            }

            setRecordList((prev) => prev.filter((el) => !successdeletedlist.includes(Number(el.id))));
            setRowCountState((prev) => prev - successdeletedlist.length);

            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `${successdeletedlist.length} of ${updateRequestList.length} records deleted!`,
                    variant: 'alert',
                    alert: { color: 'success' },
                    close: false
                })
            );
        } catch (err: any) {
            console.log(err);
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Delete records failed: ${err.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
        }
    };

    // Change Grid handlers
    const onStateChange = (state: GridState) => {
        const actualState = apiRef.current.exportState();
        densityFactorRef.current = state?.density.factor ?? 1;
        const orderedFieldsCopy = _.cloneDeep(currentState?.columns?.orderedFields);
        if (orderedFieldsCopy?.includes('__reorder__')) orderedFieldsCopy?.shift();
        if (
            _.isEqual(currentState?.pagination, actualState?.pagination) &&
            _.isEqual(currentState?.filter, actualState?.filter) &&
            _.isEqual(currentState?.pinnedColumns, actualState?.pinnedColumns) &&
            _.isEqual(currentState?.preferencePanel, actualState?.preferencePanel) &&
            _.isEqual(currentState?.columns?.columnVisibilityModel, actualState?.columns?.columnVisibilityModel) &&
            _.isEqual(orderedFieldsCopy, actualState?.columns?.orderedFields) &&
            densityFactorRef.current === Number(localStorage.getItem('grid_density_factor'))
        ) {
            setShowSaveChanges(false);
        } else {
            setShowSaveChanges(true);
        }
    };

    const onPreferenceSave = () => {
        const actualState = apiRef.current.exportState();
        setCurrentState(actualState);
        setShowSaveChanges(false);
    };

    const handleDetailPanelExpandedRowIdsChange = useCallback(
        (newIds: GridRowId[]) => {
            // debugger;
            if (!isEditingLineItem) {
                if (newIds.length) setDetailPanelExpandedRowIds([newIds[newIds.length - 1]]);
                else setDetailPanelExpandedRowIds([]);
                return;
            }

            setNewRowId(newIds[newIds.length - 1] as number);
            setShowCloseDetailsPanel(true);
        },
        [isEditingLineItem]
    );

    const handleDetailExpanedPanelConfirm = () => {
        setDetailPanelExpandedRowIds([newRowId as number]);
        setShowCloseDetailsPanel(false);
        setIsEditingLineItem(false);
    };

    const handleChangeDefaultGrid = (newDefault: IGridPreference | null, applyQueryParams?: boolean) => {
        setShowSaveChanges(false);
        if (!headerList || loadingLineItems) return;
        if (newDefault) {
            setDefaultGrid(newDefault);
            const state = generateGridState(
                newDefault,
                headerList,
                !!lineItemObj && isAllowedLineItemAccess,
                listValuesByListId,
                applyQueryParams ? (queryParams as Record<string, string>) : undefined
            );
            const initialFilterModel = state.filter?.filterModel || { items: [] };
            const initialSortModel = state.sorting?.sortModel || [{ field: 'createdAt', sort: 'desc' }];
            const initialPageSize = newDefault.gridOptions[0].pageSize || 25;

            setInitialState({ loading: false, state });
            setCurrentPageSize(newDefault.gridOptions[0].pageSize || 25);
            setCurrentState(state);

            setNewFilterModel(initialFilterModel);
            setAppliedFilterModel(initialFilterModel);

            if (!hasLoadedInitialStateRef.current) {
                hasLoadedInitialStateRef.current = true;
                handleSubmitAndSortFilters(initialSortModel, initialFilterModel, getRecords, initialPageSize);
            } else if (apiRef.current.restoreState) {
                apiRef.current.restoreState(state);
            }
        } else {
            setInitialState({ loading: false });
        }
    };

    // Delete filters
    const handleResetRowFilters = async () => {
        const revertedFilters = { items: [], logicOperator: 'and' as GridLogicOperator };
        apiRef.current.setFilterModel(revertedFilters);
        const sortModel = gridSortModelSelector(apiRef);
        setShowResetRowFilters(false);
        await handleSubmitAndSortFilters(sortModel, revertedFilters);
    };
    const handleResetRowOrder = async () => {
        const revertedSort = [{ field: 'createdAt', sort: 'desc' as GridSortDirection }];
        apiRef.current.setSortModel(revertedSort);
        const filterModel = gridFilterModelSelector(apiRef);
        setShowResetRowOrder(false);
        await handleSubmitAndSortFilters(revertedSort, filterModel);
    };
    // Refresh
    const handleGridRefresh = async (initialVariables?: FindRecordHeaderWithFilteringVariables) => {
        setIsLoadingPage(true);
        const variables = initialVariables || {
            data: {
                recordTypeId,
                tenantId: Number(localStorage.getItem('tenant_id')),
                enabled: showOnlyEnabledRecords || undefined,
                filters
            },
            pagination: {
                offset: currentPage * currentPageSize,
                limit: currentPageSize
            }
        };

        const { data } = await getRecords({ variables });
        if (data) {
            setRowCountState((prev) => data.filterAndSortRecordHeader.total ?? prev);
            setRecordList(data.filterAndSortRecordHeader.records);
        }
        setIsLoadingPage(false);
        setCurrentPage(currentPage);
    };

    // Pagination
    const handlePageChange = async (page: number) => {
        if (!defaultGrid || initialState.loading) return;
        setIsLoadingPage(true);
        const { data } = await getRecords({
            variables: {
                data: {
                    recordTypeId,
                    tenantId: Number(localStorage.getItem('tenant_id')),
                    enabled: showOnlyEnabledRecords || undefined,
                    filters
                },
                pagination: {
                    offset: page * currentPageSize,
                    limit: currentPageSize
                }
            }
        });
        if (data) {
            setRowCountState((prev) => data.filterAndSortRecordHeader.total ?? prev);
            setRecordList(data.filterAndSortRecordHeader.records);
        }
        setIsLoadingPage(false);
        setCurrentPage(page);
    };

    const handlePageSizeChange = async (pageSize: number) => {
        if (!defaultGrid || initialState.loading) return;
        GoogleAnalyticsEventHandler({ action: 'page_size_changed', category: 'Grid', label: gridName, value: pageSize });
        setCurrentPage(0);
        setIsLoadingPage(true);
        setCurrentPageSize(pageSize);
        const { data } = await getRecords({
            variables: {
                data: {
                    tenantId: Number(localStorage.getItem('tenant_id')),
                    enabled: showOnlyEnabledRecords || undefined,
                    recordTypeId,
                    filters
                },
                pagination: {
                    offset: 0,
                    limit: pageSize
                }
            }
        });
        if (data) {
            setRowCountState((prev) => data.filterAndSortRecordHeader.total ?? prev);
            setRecordList(data.filterAndSortRecordHeader.records);
        }
        setIsLoadingPage(false);
    };

    const handleFilterChange = async (model: GridFilterModel) => {
        setShowResetRowFilters(true);
        setNewFilterModel(model);
    };

    const handleSortChange = async (model: GridSortModel) => {
        setShowResetRowOrder(true);
        const filterModel = gridFilterModelSelector(apiRef);
        await handleSubmitAndSortFilters(model, filterModel);
    };

    const handleClickApply = async () => {
        const sortModel = gridSortModelSelector(apiRef);
        const filterModel = gridFilterModelSelector(apiRef);

        setAppliedFilterModel(filterModel);
        await handleSubmitAndSortFilters(sortModel, filterModel, getRecords);
    };

    const handleSubmitAndSortFilters = async (
        initialSortModel: GridSortModel,
        initialFilterModel: GridFilterModel,
        getDataFn: (options: any) => Promise<any> = fetchMore,
        limit: number = currentPageSize
    ) => {
        // Validate initial filters and sort
        if (initialFilterModel.items.length > 0) {
            setShowResetRowFilters(true);
        } else {
            setShowResetRowFilters(false);
        }
        if (initialSortModel.length >= 1) {
            if (initialSortModel[0].field === 'createdAt' && initialSortModel[0].sort === 'desc' && initialSortModel.length === 1) {
                setShowResetRowOrder(false);
            } else {
                setShowResetRowOrder(true);
            }
        } else {
            setShowResetRowOrder(false);
        }
        const filterModel = initialFilterModel;
        const sortModel = initialSortModel;
        // By Default sort by created date if theres no sort model
        if (sortModel.length === 0) sortModel.push({ field: 'createdAt', sort: 'desc' });

        const filters = generateFilterPayloads(filterModel, sortModel, headerList);
        setIsLoadingPage(true);
        setFilters(filters);
        const { data } = await getDataFn({
            variables: {
                data: {
                    tenantId: Number(localStorage.getItem('tenant_id')),
                    enabled: showOnlyEnabledRecords || undefined,
                    recordTypeId,
                    filters
                },
                pagination: {
                    offset: 0,
                    limit
                }
            }
        });
        setRowCountState((prev) => data?.filterAndSortRecordHeader.total ?? prev);
        setRecordList(data?.filterAndSortRecordHeader.records || []);
        setIsLoadingPage(false);
    };

    const handleResetFilters = ({ openedPanelValue }: GridPreferencePanelParams) => {
        if (openedPanelValue === GridPreferencePanelsValue.filters && appliedFilterModel && apiRef.current) {
            const filterModel = gridFilterModelSelector(apiRef);
            const sortModel = gridSortModelSelector(apiRef);
            if (filterModel.items.length === 0) handleSubmitAndSortFilters(sortModel, filterModel);
            else apiRef.current.setFilterModel(appliedFilterModel);
        }
    };

    // Edit dialog
    const handleShowEditDialog = async () => {
        await modal.showConfirmation({
            title: 'Keep Changes?',
            content: `Are you sure you want to keep these changes?`,
            loadingText: 'Updating',
            forwardButtonText: 'Yes, Confirm',
            actionButtonText: 'No, Cancel',
            onForward: onSaveEdition,
            onAction: null,
            showCheckbox: true,
            actionType: 'edit',
            sectionTitle: 'record_grid',
            recordType: String(gridName.toLowerCase().replaceAll(' ', '_'))
        });
    };

    const handleClickBulkEdit = () => {
        if (!headerList) return;

        setColumnsEnabledToBulkEdit(
            Object.keys(headerList)
                .filter((key) => ['standart', 'standard'].includes(headerList[key as keyof IRecordFieldsList].visibility.toLowerCase()))
                .filter(filterNotAllowedFields(fieldAccessList, recordTypeId, roleId))
                .filter((key) => (key === 'enabled' ? isAllowedDelete : true))
                .filter((key) => key !== 'id')
                .map((key) => ({
                    key,
                    editable: !!getEditableField(key, fieldAccessList, recordTypeId, roleId)
                }))
                .filter((el) => el.editable)
                .map((el) => el.key)
        );
    };

    useEffect(() => {
        if (columnsEnabledToBulkEdit.length) setBulkEditDialogOpen(true);
    }, [columnsEnabledToBulkEdit]);

    // Apollo Error Handlers
    useEffect(() => {
        if (headerError) {
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Failed to fetch record fields: ${headerError.message.replaceAll(':', ',')}`,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
        }

        if (accessError) {
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Failed to fetch fields access: ${accessError.message.replaceAll(':', ',')}`,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
        }
        if (lineItemsError) {
            storeDispatch(
                openSnackbar({
                    open: true,
                    message: `Failed to fetch line items: ${lineItemsError.message.replaceAll(':', ',')}`,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
        }
    }, [accessError, headerError, lineItemsError, storeDispatch]);

    // This was used for a legacy feature that check last cell editing for used edit in bulk
    // useEffect(() => {
    //     Object.keys(cellModesModel).some((key) => {
    //         const column =
    //             Object.keys(cellModesModel[key]).find((columns) => Object.keys(cellModesModel[key][columns]).includes('value')) || '';
    //         if (column) setEditingId(key);
    //         setEditableField({ [column]: cellModesModel[key][column] });
    //         return column;
    //     });
    // }, [cellModesModel]);

    // const { loading: userPreferencesLoading, data: userPreferencesData } = useQuery<ISaasMyGridPreference>(PREFERENCES_BACKOFFICE, {
    //     variables: { gridName: gridName || 'bills' },
    //     onCompleted(data) {
    //         if (!data || !data.SaasMyGridPreferences?.length) {
    //             handleChangeDefaultGrid(null);
    //         } else {
    //             const defaultGrid = getDefaultGrid(preferenceList);
    //             setPreSelectedGridId(+defaultGrid.id);
    //             handleChangeDefaultGrid(defaultGrid);
    //         }
    //     }
    // });

    // const preferenceList = useMemo(() => userPreferencesData?.SaasMyGridPreferences || [], [userPreferencesData]);
    return (
        <>
            <Grid container sx={{ height: 'calc(100% - 50px)' }}>
                <Grid item xs={showObjectPanel ? 9 : 12} sx={{ height: '100%' }}>
                    <GridOptions
                        onPreferenceSave={onPreferenceSave}
                        loadPreferences={!headerLoading && !loadingLineItems && !accessLoading && !loadingListsValues}
                        showSaveChanges={showSaveChanges}
                        setDefaultGrid={handleChangeDefaultGrid}
                        onLoading={setLoadingPreferences}
                        inputValue={inputSearch}
                        onChangeInputValue={setInputSearch}
                        gridName={gridName}
                        densityFactor={densityFactorRef}
                        apiRef={apiRef}
                        recordTypeId={recordTypeId}
                        createOrder={handleNewOrder}
                        isSuperRecordType={isSuperRecordType}
                    />
                    {(headerLoading || listLoading || loadingPreferences || accessLoading) && <SkeletonGrid />}
                    {!!headerFields.length && !initialState.loading && (
                        <div
                            id="data-grid-container"
                            style={{
                                height: '100%',
                                padding: '0.5rem',
                                display: listLoading || accessLoading ? 'none' : 'block',
                                overflowY: 'auto'
                            }}
                        >
                            <DataGridPro
                                showColumnVerticalBorder
                                showCellVerticalBorder
                                paginationMode="server"
                                filterMode="server"
                                sortingMode="server"
                                sx={(theme) => ({
                                    '& .MuiDataGrid-pinnedColumnHeaders, & .MuiDataGrid-pinnedColumns': {
                                        boxShadow: 'none'
                                    },
                                    // '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
                                    //     display: 'none'
                                    // },
                                    '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
                                        '& svg[data-testid="CheckBoxIcon"]': {
                                            color: `${theme.palette.primary.dark} !important`,
                                            backgroundColor: 'transparent !important'
                                        }
                                    },
                                    '& .MuiDataGrid-columnHeaders': {
                                        borderBottom: '2.5px solid #E0E0E0'
                                    },
                                    '& .MuiDataGrid-withBorderColor': {
                                        borderRight: '1px solid #E0E0E0',
                                        borderBottom: '1px solid #E0E0E0'
                                    },
                                    '& .MuiDataGrid-columnHeader': {
                                        borderRight: '1px solid transparent'
                                    },
                                    '& .MuiDataGrid-cellCheckbox > svg[data-testid="CheckBoxIcon"]': {
                                        color: `${theme.palette.primary.dark} !important`
                                    },
                                    '& .Mui-selected': {
                                        backgroundColor: `${theme.palette.primary.dark} !important`,
                                        color: `#FFF !important`,
                                        '& div button, div a, div p': { color: '#FFF' },
                                        '& div button': { color: '#FFF' },
                                        '& svg[data-testid="CheckBoxIcon"], svg[data-testid="CheckBoxOutlineBlankIcon"]': {
                                            color: '#FFF !important',
                                            backgroundColor: 'transparent !important'
                                        }
                                    },
                                    // '& .MuiDataGrid-selectedRowCount': {
                                    //     display: 'none'
                                    // },
                                    '& .MuiDataGrid-footerContainer': {
                                        border: 'none !important'
                                    }
                                })}
                                apiRef={apiRef}
                                slots={{
                                    toolbar: CustomToolbar,
                                    filterPanel: FilterPanel,
                                    pagination: PaginationFooter
                                }}
                                onPreferencePanelClose={handleResetFilters}
                                loading={listLoading || isLoadingPage || loadingPreferences}
                                density={getDensityFactor(densityFactorRef.current)}
                                pageSizeOptions={[25, 50, 100, 200, 500, 1000, 2000, 3000]}
                                sortingOrder={['desc', 'asc']}
                                onRowSelectionModelChange={(model) => setCheckbox(model as string[])}
                                columns={headerFields}
                                rows={tableList || []}
                                initialState={initialState.state}
                                cellModesModel={cellModesModel}
                                onStateChange={onStateChange}
                                onCellEditStop={handleRowEditStop}
                                onCellKeyDown={handleCellKeydown}
                                onCellModesModelChange={handleEditRowsModelChange}
                                isRowSelectable={() => !checkIsEditing(cellModesModel)}
                                rowCount={rowCountState}
                                paginationModel={{ page: currentPage, pageSize: currentPageSize }}
                                onSortModelChange={handleSortChange}
                                onFilterModelChange={handleFilterChange}
                                getDetailPanelContent={
                                    lineItemObj && isAllowedLineItemAccess
                                        ? ({ row }: GridRowParams<IRecordHeaders>) => (
                                              <CustomDetailPanel
                                                  headers={lineItemObj}
                                                  isOpen={detailPanelExpandedRowIds.includes(row.id)}
                                                  recordId={Number(row.id)}
                                                  key={row.id}
                                                  recordTypeId={recordTypeId}
                                                  onActiveEditing={setIsEditingLineItem}
                                              />
                                          )
                                        : undefined
                                }
                                getDetailPanelHeight={() => 460}
                                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                                onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                                checkboxSelection
                                pagination
                                rowReordering={false}
                                disableRowSelectionOnClick
                                getRowClassName={getRowCustomClassName(classes)}
                                slotProps={{
                                    filterPanel: {
                                        isApplied: isSameFilterModel,
                                        isLoading: isLoadingPage,
                                        onClickApply: handleClickApply,
                                        disableApply: !checkFilterValue(newFilterModel || ({} as GridFilterModel)),
                                        filterModel: apiRef?.current?.state?.filter ? gridFilterModelSelector(apiRef) : undefined
                                    } as FilterPanelProps,

                                    toolbar: {
                                        recordType: String(gridName.toLowerCase().replaceAll(' ', '_')),
                                        handleGridRefresh,
                                        showResetRowFilters,
                                        handleResetRowFilters,
                                        showResetRowOrder,
                                        handleResetRowOrder,
                                        showOnlyEnabledRecords,
                                        onClickEdit: ToggleEdit,
                                        rowsEditModel: cellModesModel,
                                        onSave: handleShowEditDialog,
                                        onDelete: handleDelete,
                                        onToggleShowRecords: () => {
                                            const newVal = !showOnlyEnabledRecords;
                                            setShowOnlyEnabledRecords(newVal);
                                            handleGridRefresh({
                                                data: {
                                                    recordTypeId,
                                                    tenantId: Number(localStorage.getItem('tenant_id')),
                                                    enabled: newVal || undefined,
                                                    filters
                                                },
                                                pagination: {
                                                    offset: 0,
                                                    limit: currentPageSize
                                                }
                                            });
                                        },
                                        onClickBulkEdit: handleClickBulkEdit,
                                        updateLoading,
                                        isSuperRecordType
                                    } as ICustomToolbar,

                                    pagination: {
                                        classes,
                                        rowCountState,
                                        currentPage,
                                        handlePageChange,
                                        currentPageSize,
                                        handlePageSizeChange
                                    } as PaginationFooterProps,
                                    columnsPanel: {
                                        getTogglableColumns
                                    }
                                }}
                            />
                        </div>
                    )}
                </Grid>
                {showObjectPanel && (
                    <ClickAwayListener
                        onClickAway={(e) => {
                            if (!modal.isShown) {
                                setSelectedObjectToEdit(null);
                                setShowObjectPanel(false);
                                setObjectProperties([]);
                                setObjectPanelMode('view');
                                const { target } = e;
                                if (target instanceof HTMLElement) {
                                    const classList = target.classList; // DOMTokenList
                                    if ([...classList].includes('gridObjectValue')) {
                                        handleClickObject(
                                            target.getAttribute('data-field') ?? '',
                                            +(target.getAttribute('data-id') ?? 0),
                                            JSON.parse(target.getAttribute('data-objdef') ?? '[]'),
                                            target.getAttribute('data-editable') === 'true',
                                            target.getAttribute('data-objectdefinitionid'),
                                            target.getAttribute('data-listobject') === 'true'
                                        );
                                    }
                                }
                            }
                        }}
                    >
                        <Grid ref={objectPanelRef} item xs={3} sx={{ maxHeight: '100%' }}>
                            <Box sx={{ position: 'relative', height: 'calc(100% + 51px)', overflowY: 'auto' }}>
                                {objectPanelMode === 'view' && (
                                    <ObjectPanelView
                                        objectName={selectedObjectToEdit?.field ?? ''}
                                        loadingObjectProperties={loadingObjectProperties}
                                        handleClosePanel={() => {
                                            setSelectedObjectToEdit(null);
                                            setShowObjectPanel(false);
                                            setObjectProperties([]);
                                            setObjectPanelMode('view');
                                        }}
                                        handleOpenEditPanel={() => setObjectPanelMode('edit')}
                                        objectProperties={objectProperties}
                                        renderEditButton={isAllowedToEditObject || isListObjectSelected}
                                        isListObject={isListObjectSelected}
                                    />
                                )}
                                {objectPanelMode === 'edit' && (
                                    <ObjectPanelEditForm
                                        objectName={selectedObjectToEdit?.field ?? ''}
                                        objectProperties={objectProperties}
                                        setObjectProperties={setObjectProperties}
                                        handleUpdateObjectGrid={handleEditObjectFormInteraction}
                                        handleClosePanelGrid={handleEditObjectFormInteraction}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </ClickAwayListener>
                )}
            </Grid>

            <UpdateRowsBanner percentage={bulkUpdatePercentage} value={bulkUpdateData.value} total={bulkUpdateData.total} />
            <CloseDetailsPanelDialog
                open={showCloseDetailsPanel}
                onClose={() => setShowCloseDetailsPanel(false)}
                onSubmit={handleDetailExpanedPanelConfirm}
            />
            <BulkEditDialog
                formatHeaderName={formatHeaderName}
                setOpen={setBulkEditDialogOpen}
                open={bulkEditDialogOpen}
                recordTypeId={recordTypeId}
                recordTypeName={gridName}
                columns={columnsEnabledToBulkEdit}
                fields={headerList}
                onSaveBulkEdition={handleSaveBulkEditionSaveConfirmation}
            />
        </>
    );
};
