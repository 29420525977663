import React, { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

export const moduleMapper = (url: string) => {
    const BackofficeUsers = Loadable(lazy(() => import('views/backoffice/users')));
    const BackofficeRoles = Loadable(lazy(() => import('views/backoffice/roles')));
    const BackofficeRoleAliases = Loadable(lazy(() => import('views/backoffice/roleAliases')));
    const DashboardPage = Loadable(lazy(() => import('views/dashboard')));
    const BackofficeRecordDetails = Loadable(lazy(() => import('views/backoffice/recordDetails')));
    const CustomLists = Loadable(lazy(() => import('views/backoffice/CustomLists')));
    // const DiscoverPage = Loadable(lazy(() => import('views/discover')));

    switch (url) {
        case 'users':
            return <BackofficeUsers />;
        case 'roles':
            return <BackofficeRoles />;
        case 'role-aliases':
            return <BackofficeRoleAliases />;
        case 'dashboard':
            return <DashboardPage />;
        case 'record-details':
            return <BackofficeRecordDetails />;
        case 'custom-lists':
            return <CustomLists />;
        // case 'discover':
        //     return <DiscoverPage />;
        default:
            return null;
    }
};
